/*.detail-text .h1:after, .h2:after, h1:after, h2:after {
    content: none;
}*/


.link-list {
    &-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &-content {
        &.link-list-content-has-border {
            padding: 2rem;
        }
    }

    &-ul {
        padding-left: 16px;
    }

    &-item {
        &::after {
            content: "\003E";
            padding-left: 0.5rem;
        }
    }

    &-image {
        max-width: 250px;
        max-height: 250px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center
        }
    }
}


.bg-primary,
.bg-secondary,
.bg-tertiary {
    .link-list-content {
        color: $white;
        .link-list-ul {
            .link-list-item-link {
                color: $white;
            }
        }
    }
}