@use "sass:math";

.job-item {
    position: relative;
    padding-top: 140%;
    cursor: pointer;
}

.job-item-title {
    position: absolute;
    background-color: #fff;
    color: #0b2d44;
    bottom: 0;
    right: 0;
    left: 30px;
    z-index: 1;
    margin: 0;
    font-size: precision(math.div($h2-font-size, 3), 2);
    text-shadow: 0 3px 6px #00000029;
    padding: 30px 20px;
    font-size: 24px;
}

.job-item-title::after {
    content: none;
}

.job-item-img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.job-item-img-wrapper > div {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    margin: 0px;
}

.job-item-img-wrapper > div > img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}

.job-item-img-wrapper > div > div {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
}

.job-item-img-wrapper > div > div > img {
    max-width: 100%;
    display: block;
    margin: 0px;
    border: none;
    padding: 0px;
}
