footer {
    background-color: $quinary;
    padding: 3rem 0 1.5rem;

    a, & {
        color: color-contrast($quinary,$white) !important;
    }

    a {
        text-decoration: none;
    }

    ul {
        &.footer-list {
            list-style: none;
            padding: 0;
            margin-bottom: 0;
            gap: 1.15rem;
            font-size: .875rem;

            a {
                display: flex;
                flex-direction: row;
                align-items: center;

                .bi {
                    font-size: 1.25rem;
                    line-height: 1;
                }
            }
        }

        &.footer-list-bottom {
            list-style: none;
            padding: 0;
            flex-direction: row;
            margin-bottom: 0;
            gap: 2.5rem;
            font-size: .75rem;
        }

        &.social-list {
            list-style: none;
            padding: 0;
            flex-direction: row;
            margin-bottom: 0;
            justify-content: flex-end;
            gap: .5rem;
            font-size: 1.5rem;
            line-height: 1;
        }
    }
    // footer dirty overwrites
    h5 {
        margin-bottom: 1.5rem;
    }

    img {
        object-position: left top;
    }
    // bottom row items center alignment
    .umb-block-grid__layout-container {
        .umb-block-grid__layout-item {
            &:nth-last-child(-n+3), &:nth-last-child(-n+2), &:nth-last-child(-n+1) {
                .content-block-content-wrapper {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }

    @media(max-width: 1023px) {
        .umb-block-grid__layout-container {
            .umb-block-grid__layout-item {
                &:nth-of-type(5) {
                    .content-block-content-wrapper {
                        margin-top: 2.5rem;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {

        .umb-block-grid__layout-container {
            flex-direction: row;
            flex-wrap: wrap;

            .umb-block-grid__layout-item {
                width: 100%;

                &:nth-of-type(3), &:nth-of-type(4) {
                    width: 50%;
                }
                // re arange footer fixed for design
                &:nth-of-type(1) {
                    order: 1;
                }

                &:nth-of-type(2) {
                    order: 2;
                }

                &:nth-of-type(3) {
                    order: 3;
                }

                &:nth-of-type(4) {
                    order: 4;
                }

                &:nth-of-type(5) {
                    order: 5;
                }

                &:nth-of-type(6) {
                    order: 6;
                }

                &:nth-of-type(7) {
                    order: 9;
                    margin-top: 1rem;
                }

                &:nth-of-type(8) {
                    order: 8;
                    margin-top: 2.5rem
                }

                &:nth-of-type(9) {
                    order: 7;
                    margin-top: 1rem;
                }
            }
        }

        img {
            max-width: 80%;
            margin-bottom: 2.5rem;
        }

        ul {
            &.footer-list {
                gap: 1rem;
            }

            &.social-list {
                justify-content: flex-start;
            }
        }
    }
}
