.btn {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    border-radius: 6px;
    padding: 12px 18px;

    &.dropdown-toggle {
        --#{$prefix}btn-bg: transparent;
        --#{$prefix}btn-border-color: #{$black};
        --#{$prefix}btn-active-bg: transparent;
        --#{$prefix}btn-active-color: #{$black};
        --#{$prefix}btn-hover-bg: transparent;
        --#{$prefix}btn-hover-color: #{$black};
        --#{$prefix}btn-hover-border-color: #{$black};
        --#{$prefix}btn-active-border-color: #{$black};
        display: flex;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: flex-start;
        justify-content: space-between;
        padding: 10px 8px 10px 16px;
        min-width: 250px;

        &:after {
            content: "\F282";
            font-family: "bootstrap-icons";
            border: 0;
            line-height: 1;
            font-size: 1rem;
            font-weight: 600;
            transition: transform ease-out .3s;
            margin-left: 1rem;
        }

        &[aria-expanded="true"] {
            &:after {
                transform: rotate(-180deg);
            }
        }

        + .dropdown-menu {
            --#{$prefix}dropdown-bg: #{$lightGrey};
            --#{$prefix}border-width: 0;
            --#{$prefix}dropdown-font-size: 1rem;
            --#{$prefix}dropdown-padding-y: 0;
            --#{$prefix}dropdown-item-padding-y: .5rem;
            --#{$prefix}dropdown-link-hover-bg: #{$grey};
            --#{$prefix}dropdown-border-radius: 6px;
            --#{$prefix}dropdown-link-active-color: #{$black};
            --#{$prefix}dropdown-link-active-bg: transparent;
            min-width: 100%;

            .dropdown-item {
                font-weight: 500;

                &.active {
                    font-weight: 700;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &:after {
                        content: "\F633";
                        font-family: "bootstrap-icons";
                        line-height: 1;
                        font-size: 1rem;
                        font-weight: 600;
                        margin-left: 1rem;
                        color: $primary
                    }
                }
            }
        }
    }
}

.btn-link {
    --#{$prefix}btn-color: #{$black};
    --#{$prefix}link-hover-color: #{$primary};
    --#{$prefix}btn-border-color: #{$black};
    --#{$prefix}btn-hover-border-color: #{$primary};
    padding-left: 0;
    padding-right: 0;
    text-decoration: none;
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
}

.btn-arrow {
    display: inline-block;

    &:after {
        content: "\F135";
        font-family: "bootstrap-icons";
        margin-left: 12px;
        font-size: 1.5rem;
        line-height: 1;
        vertical-align: middle;
    }
}

.btn-download {
    display: inline-block;

    &:after {
        content: "\F30A";
        font-family: "bootstrap-icons";
        margin-left: 12px;
        font-size: 1.15rem;
        line-height: 1;
        vertical-align: middle;
    }
}


// overwrite for design
.btn-outline-dark {
    --#{$prefix}btn-hover-bg: transparent;
    --#{$prefix}btn-hover-color: #{$primary};
    --#{$prefix}btn-hover-border-color: #{$primary};
    --#{$prefix}btn-active-bg: #{$primary};
    --#{$prefix}btn-active-color: #{$white};
    --#{$prefix}btn-active-border-color: #{$primary};

    &:focus {
        //background-color: darken($primary, 16.67%);
        background-color: #E04731;
        color: $white;
        //border-color: lighten($primary, 11%);
        border-color: #FF8D7D;
    }
}
