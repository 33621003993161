.download-block {
    &-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    &-btn {
        order: 1;
    }

    &-text {
        transition: margin-left .2s ease-in-out;
    }

    /*&-btn:hover + .download-block-text {
        margin-left: -1.25rem;
    }*/

    @include media-breakpoint-down(md) {
        &-inner {
            flex-direction: column;
            align-items: flex-start;
            gap: 3rem;
        }
    }
}
