ul {
    padding-left: 1.2rem;
    list-style: square;
    list-style-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciID4KICA8cGF0aCBkPSJtMCwwdjVoNVYwSDBaIiBzdHlsZT0iZmlsbDogI2Y4NWM0Njsgc3Ryb2tlLXdpZHRoOiAwcHg7Ii8+Cjwvc3ZnPg==");
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {

        &::marker {
            color: $primary;
            font-size: 1rem;
        }
    }

    &.check-list {
        list-style: none;
        padding: 0;
        gap: 0;

        li {
            padding: 2rem 1.5rem;
            font-size: 1.125rem;
            line-height: 1.05;
            font-weight: 500;
            display: flex;

            .icon {
                margin-right: 2.5rem;
                color: $primary;
            }

            &:not(:first-of-type) {
                border-top: 3px solid $grey;
            }
        }

        @include media-breakpoint-down(md) {
            li {
                padding: 1.5rem 0;
                font-size: 1rem;
            }
        }
    }

    &.tag-list {
        display: flex;
        flex-direction: row;
        padding: 0;
        list-style: none;
        gap: .5rem;
        margin-bottom: 0;

        li {
            border: 1px solid $borderColor;
            border-radius: 2px;
            padding: 6px 12px;
            font-size: .875rem;
            font-weight: 500;
            line-height: 1.5;
        }
    }

    &.pagination {
        --#{$prefix}pagination-border-width: 0;
        --#{$prefix}pagination-bg: transparent;
        --#{$prefix}pagination-padding-y: .5rem;
        --#{$prefix}pagination-padding-x: .875rem;
        --#{$prefix}pagination-active-bg: transparent;
        --#{$prefix}pagination-active-color: #{$black};
        --#{$prefix}pagination-hover-color: #{$black};
        --#{$prefix}pagination-focus-bg: transparent;
        --#{$prefix}pagination-focus-color: #{$primary};
        --#{$prefix}pagination-focus-box-shadow: 0;
        --#{$prefix}pagination-active-border-color: #{$primary};
        --#{$prefix}pagination-disabled-bg: transparent;
        --#{$prefix}pagination-disabled-color: #{$black};
        --#{$prefix}link-color: #{$black};
        flex-direction: row;
        justify-content: center;

        .page-link {
            font-size: 1.15rem;
            font-weight: 500;
        }

        .page-item {
            &.active {
                border-bottom: 1px solid $primary;
            }

            &.disabled {
                opacity: .3;
            }
        }

        .pagination-arrow {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: .75rem;
            font-weight: 600;
        }
    }
}
ol {
    padding-left: 1.2rem;
}
