@use "sass:math";

.case-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 30px;
    background-color: #0b2d44;
    cursor: pointer;
}

.case-item-title-box-title {
    color: #fff;
    padding-bottom: 0;
    margin-bottom: 0;
}

@media only screen and (max-width: 576px) {
    .case-item {
        padding: 0px;
        background: none !important;
    }
}

@media only screen and (max-width: 576px) {
    .case-item-title-box-title {
        color: #0b2d44;
        margin-bottom: 15px;
    }
}

.case-item-tagline {
    font-size: precision(math.div($h5-font-size, 1.75), 2);
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
}

@media only screen and (max-width: 576px) {
    .case-item-tagline {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .case-item-img {
        height: 100%;
    }
}

.case-item-img-wrapper {
    max-width: 100%;
    margin: 30px 0 0;
}

@media only screen and (max-width: 576px) {
    .case-item-img-wrapper {
        margin: 0px;
        height: 100%;
    }

    .case-item-img-wrapper > div {
        height: 100%;
    }
}

.case-item-img-wrapper-image {
    width: 100% !important;
    height: 100% !important;
}

.case-item-img-wrapper-image > div {
    width: 100% !important;
    height: 100% !important;
}
