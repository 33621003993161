.card {
    --#{$prefix}card-border-color: var(--card-border-color, #dee2e6);
    --#{$prefix}card-border-width: var(--card-border-width, 0px);
    --#{$prefix}card-border-radius: 0;
    --#{$prefix}card-bg: var(--card-bg, $secondary);
    --#{$prefix}card-spacer-y: 0;
    --#{$prefix}card-spacer-x: 0;
    --#{$prefix}card-title-spacer-y: 1.5rem;
    --#{$prefix}card-cap-bg: transparent;
    --#{$prefix}card-cap-padding-y: 0;
    --#{$prefix}card-cap-padding-x: 0;
    padding: 80px 40px;

    &[href] {
        &:hover,
        &:active,
        &:focus {
            --#{$prefix}card-cap-bg: var(--card-bg-hover, #{$info});
        }
    }

    .card-footer {
        &:empty {
            --#{$prefix}card-cap-padding-y: 0.2rem;
        }

        margin-top: 2.5rem;
    }
    // numbered cards
    &.card-numbered {
        height: 100%;

        .card-number {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $primary;
            color: $white;
            width: 42px;
            height: 42px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: 1.5rem;
        }

        [class*="col-"]:not(:last-of-type) & {
            border-right: 1px solid $white;
        }

        .card-text {
            p:last-of-type {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            padding: 1.5rem 2rem 1.5rem 3rem;

            .card-number {
                font-size: 1.25rem;
                width: 25px;
                height: 52px;
                padding-top: 1.5rem;
            }

            [class*="col-"]:not(:last-of-type) & {
                border-right: 0;
                border-bottom: 1px solid $white;
            }
        }
    }
    // picture cards
    &.card-picture {
        padding: 0;

        .card-picture-wrapper {
            width: 100%;
            //aspect-ratio: 416 / 535; not following design here
            aspect-ratio: 1;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .card-body {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 32px 40px;
            width: 50%;

            &.s-size {
                width: 25%;
            }

            &.m-size {
                width: 50%;
            }

            &.l-size {
                width: 75%;
            }

            &-hover {
                overflow: hidden;
                max-height: 0;

                .card-text {
                    margin-top: .5rem; // add padding to the text instead of title, to smoothen animation
                    opacity: 0;
                }
            }

            &.top {
                top: 0;
                bottom: auto;
            }
        }

        .card-tags {
            list-style: none;
            padding: 0;
            margin-bottom: 1.5rem;
            display: flex;
            flex-direction: row;
            gap: .75rem;

            li {
                padding: .25rem 0;
                border-bottom: 1px solid $supportColorBlue;
                font-size: .875rem;
                font-weight: 500;
            }

            &.green li {
                border-color: $supportColorGreen;
            }
        }

        .card-title {
            margin-bottom: 0;
        }

        .card-text {
            margin-bottom: 2rem;
        }
        // hover state
        &:hover {
            .card-body-hover {
                max-height: 500px; // just in case no js available
                transition: max-height .3s ease-in-out;

                .card-text {
                    opacity: 1;
                    transition: opacity 0.1s ease-in-out 0.1s;
                }
            }
        }
        // reverse (unhover) state
        &:not(:hover) {

            .card-body-hover {
                transition: max-height .3s ease-in-out;

                .card-text {
                    opacity: 0;
                    transition: opacity 0.3s ease-out;
                }
            }
        }

        &.horizontal-ratio {
            .card-picture-wrapper {
                aspect-ratio: 89/44;
            }

            .card-body {
                width: 25%;
            }
        }

        @include media-breakpoint-down(md) {
            .card-picture-wrapper {
                aspect-ratio: 135/98;
            }

            .card-body {
                width: 100% !important;
                position: relative;
                padding: 1.5rem;

                &.top {
                    order: -1;
                }

                &-hover {
                    max-height: none !important;

                    .card-text {
                        opacity: 1 !important;
                        margin-bottom: 1rem;
                    }
                }
            }

            &.product-variant {
                .card-body {
                    padding: 0;
                    order: initial;
                    &-hover {
                        padding: 1rem 1.5rem 1rem;
                    }
                    .card-title {
                        padding: 1rem 1.5rem;
                        position: absolute;
                        background-color: inherit;
                        transform: translateY(-99%)
                    }
                }
            }
        }
    }
    // card picture (detail page variant)
    &.card-picture-top {
        padding: 0;

        .card-picture-wrapper {
            width: 100%;
            aspect-ratio: 51 / 50;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .card-title {
            font-size: 1.250rem;
            display: inline-block;
            padding-bottom: .5rem;
            border-bottom: 2px solid $borderColor;
            margin-bottom: .75rem;
        }

        .card-body {
            padding: 2rem;
        }

        .card-text {
            font-size: .875rem;
            font-weight: 500;
        }
    }
    // card picture (detail page variant 2)
    &.card-picture-top-title {
        padding: 0;

        .card-picture-wrapper {
            width: 100%;
            aspect-ratio: 1;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center bottom;
            }
        }

        .card-title {
            font-size: 1.250rem;
            display: inline-block;
            padding-bottom: .5rem;
            border-bottom: 2px solid $borderColor;
            margin-bottom: .75rem;
            position: absolute;
            top: 1rem;
            left: 1rem;
        }

        .card-body {
            padding: 1rem;
        }

        .card-text {
            font-size: .875rem;
            font-weight: 500;
        }
    }
    //employee card
    &.card-employee {
        padding: 0;
        overflow: hidden;

        .card-picture-wrapper {
            width: 100%;
            aspect-ratio: 101 / 145;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .card-body {
            background-color: $secondary;
            padding: 2rem 2.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .card-title {
            font-size: 1.75rem;
            line-height: 1.1;
            font-weight: 700;
            margin-bottom: .5rem;

            &:before {
                content: '';
                width: 10px;
                height: 10px;
                display: block;
                background-color: $primary;
                margin-bottom: .5rem;
            }
        }

        .card-subtitle {
            font-size: 1.75rem;
            font-weight: 300;
            line-height: 1.1;
            margin: 0;
        }

        .card-text {
            font-size: 1.125rem;
            margin-top: 1rem;
        }

        @include media-breakpoint-up(md) {
            .card-body {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                opacity: 0;
                transition: transform .5s ease-out, opacity .5s ease-in-out .1s;
            }

            &:hover {
                .card-body {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
    }
    // footer card
    @include media-breakpoint-up(md) {
        &.footer-card-left, &.footer-card-right {
            .card-footer {
                margin-top: auto;
                padding-top: 2.5rem;
            }
        }



        &.footer-card-left {
            padding: 5rem 4rem 5rem 0;
        }

        &.footer-card-right {
            padding: 5rem 0 5rem 4rem;
        }
    }

    @include media-breakpoint-down(md) {
        &.footer-card-left {
            padding: 2.5rem 1.5rem;
        }

        &.footer-card-right {
            padding: 2.5rem 1.5rem;
        }
    }
}
