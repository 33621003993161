.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    line-height: 1.05;
}

h1,
.h1 {
    font-size: 4rem;
}

h2,
.h2 {
    font-size: 3rem;
}

h3,
.h3 {
    font-size: 1.5rem;
}
h4,
.h4 {
    font-size: 1.25rem;
}

h5,
.h5 {
    font-size: 1.125rem;
}

@include media-breakpoint-down(md) {
    h1,
    .h1 {
        font-size: 2.5rem;
    }

    h2,
    .h2 {
        font-size: 2.250rem;
    }

    h3,
    .h3 {
        font-size: 1.25rem;
    }

    h4,
    .h4 {
        font-size: 1rem;
    }

    h5,
    .h5 {
        font-size: 1rem;
    }
}

