.switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 30px;
    margin-bottom: 0px;
    margin-left:15px;
    margin-right: 15px;
    z-index: 1500;
    &:not(.loaded){
        visibility: hidden;
    }
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #554cb7;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    background-color: white;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #37ebb9;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    transform: translateX(40px);
}


.EN {
    display: none;
}

.EN,
.NL {
    color: white !important;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
}
.EN {
    left: 10px
}
.NL {
    right: 10px
}

.EN a,
.NL a {
    color: white;
    //padding: 5px 25px;
    border-radius: 50%;
}



input:checked + .slider .EN {
    display: block;
}

input:checked + .slider .NL {
    display: none;
}
