.cookiebox {
    display: block;
    max-width: 100%;
    width: 30rem;
    height: auto;
    bottom: 0.875rem;
    z-index: 98;
    left: 0.875rem;
    padding: 2.75rem;
    position: fixed;
    background: $quinary;
    box-shadow: 10px 10px 6px 0 rgb(0 0 0 / 15%);
    color: $white;
}

.cookiebox > p > a {
    color: $white;
    text-decoration: underline;
}

.cookiebox > p > a:hover {
    text-decoration: underline;
}

.cookiebox > div > a {
    color: $primary;
    cursor: pointer;
}

.cookiebox > div > a:hover {
    text-decoration: underline;
}

.cookiebox input[type="checkbox"] + label {
    position: relative;
    align-items: center;
    padding: 0 0 1.125rem;
    cursor: pointer;
}

.cookiebox input[type="checkbox"] {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.cookiebox input[type="checkbox"] + label:before {
    content: "";
    position: relative;
    display: inline-block;
    margin-right: 0.625rem;
    width: 20px;
    height: 20px;
    top: 0.1875rem;
    background: #fff;
}

.cookiebox input[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    top: 0.5rem;
    left: 0.1875rem;
    border-left: 3px solid $primary;
    border-bottom: 3px solid $primary;
    height: 7px;
    width: 14px;
    transform: rotate(-45deg);
}

.cookiebox .form-disabled,
.cookiebox .form-disabled * {
    cursor: not-allowed;
}

@media (max-width: 991.98px) {
    .cookiebox {
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        padding: 1.875rem;
        box-shadow: 0 -3px 5px 0 rgb(0 0 0 / 36%);
    }
}
