.content-block-content {
    .rich-text {
        p:last-of-type{
            margin-bottom: 0;
        }
    }

    
}


section:not([class*="bg-"]):not([class*="text-bg-"]) {
    .content-block-content {
        &:not([class*="bg-"]):not([class*="text-bg-"]) {
            a:not(.btn) {
                color: $primary;
            }
        }
    }
}