

.blog-item {
    position: relative;
    cursor: pointer;
}

.blog-item-img {
    padding-top: 150%;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: 50%;
}

.blog-item-box {
    position: absolute;
    padding: 35px 50px;
    left: 30px;
    right: 0;
    bottom: 0;
    background-color: #fff;
}

.blog-item-title {
    font-weight: 800;
    text-transform: uppercase;
    text-shadow: 0 3px 6px #00000029;
    color: #0b2d44;
}

.blog-item-details {
    font-size: 0.86rem;
    font-weight: 400;
    color: #0b2d44;
}

.blog-teaser-multiple {
    // blog teaser full width force inside block grid system
    margin-left: calc((100vw - 100%)/-2);
    margin-right: calc((100vw - 100%)/-2);

    section {
        flex-direction: column;
    }
}

.blog-teaser-multiple h3 {
    margin: 50px 0 50px;
    text-transform: uppercase;
}


.blog-teaser {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
   
}


.blog-teaser-item {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    flex: 1;
    max-width: 100%;
   // height: 600px;
    width: 100%;

    &-inner {
        position: relative;
        height: 600px;
    }
}


.blog-teaser-item-title {
    display: none;
}

.blog-teaser-item-img {
    position: absolute;
    left: 0;
    top:0;
    right:0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.blog-teaser-item-box {
    position: absolute;
    padding: 35px 50px;
    left: 30%;
    right: 0;
    bottom: -75px;
    background-color: white;
    transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 992px) {
    .blog-teaser-multiple h3 {
        margin: 25px 0 25px;
        text-transform: uppercase;
    }
    .blog-teaser-item-box {
        padding: 25px;
        left: 7%;
    }

    .blog-teaser-item {
        
        &-inner {
            height: 350px;
        }
    }
}


@media only screen and (max-width: 767px) {
    .blog-teaser-item-title {
        display: unset !important;
        position: absolute;
        bottom: 0;
        padding: 10px;
        background-color: white;
    }

    .blog-teaser-item-title > h3 {
        margin: 0;
    }

    .blog-teaser-item-box {
        display: none;
    }

    .blog-teaser {
        height: 100%;
        width: calc(100% - 30px);
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 10px;
        flex-wrap: nowrap
    }

    .blog-teaser::-webkit-scrollbar {
        height: 10px;
        width: 0;
        background-color: #2b78999f;
        border-radius: 50px;
    }

    .blog-teaser::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #0b2d44;
        border: 1px solid #000;
    }

    .blog-teaser-item {
        flex: 0 0 40%;
        max-width: 100%;
        &-inner {
            height:auto;
        }

        .blog-teaser-item-img {
            width: 250px !important;
            height: 250px !important;
            padding-top: 100%;
            height: auto;
            position: relative;
        }
    }
    
}

@media only screen and (max-width: 576px) {
    .blog-teaser-item-box {
        position: unset;
        opacity: 1;
        left: 10%;
        bottom: -80px;
        padding: 25px;
        width: 250px;
        background-color: rgba(255, 255, 255, 0.8);
    }
}


