.hero-section {
    padding: 0;
    background-color: $lightGrey;
    // design allignment fix
    h1 {
        margin-top: -10px;
    }

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: $lightGrey;
        top: 0;
        left: calc((100vw - 100%) / -2);
        width: 100vw;
        height: 100%;
    }

    .text-section {
        padding: 5rem 0;
    }
    // custom cols for text, to match full width cols
    /*@include media-breakpoint-up(md) {
        .text-section {
            margin-left: calc((100vw - (100% + 12px)) / -2);
            width: 100vw;
        }

        .custom-left-col {
            padding-left: calc(((100vw - 720px) / 2 + 17px));
             padding-right: 2rem;
        }

        .custom-right-col {
            padding-right: calc(((100vw - 720px) / 2 + 17px));
            padding-left: 0;
        }
    }*/


    @include media-breakpoint-up(lg) {
        .text-section {
            margin-left: calc((100vw - (100% + 12px)) / -2);
            width: 100vw;
        }

        .custom-left-col {
            padding-left: calc(((100vw - 960px) / 2 + 17px));
            padding-right: 2rem;
        }

        .custom-right-col {
            padding-right: calc(((100vw - 960px) / 2 + 17px));
            padding-left: 0;
        }
    }

    @include media-breakpoint-up(xl) {
        .custom-left-col {
            padding-left: calc(((100vw - 1140px) / 2 + 17px));
        }

        .custom-right-col {
            padding-right: calc(((100vw - 1140px) / 2 + 17px));
            padding-left: 0;
        }
    }


    @include media-breakpoint-up(xxl) {
        .custom-left-col {
            padding-left: calc(((100vw - 1320px) / 2 + 17px));
        }

        .custom-right-col {
            padding-right: calc(((100vw - 1320px) / 2 + 17px));
            padding-left: 0;
        }
    }



    .image-section {
        width: 100vw;
        aspect-ratio: 100 / 47;
        margin-left: calc((100vw - (100% + 12px)) / -2);
        display: flex;
        flex-direction: row;

        .left-image, .right-image {
            flex-shrink: 0;
            width: 58.33333333%;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .right-image {
            width: 41.66666667%;

            &.text-inside {
                .hero-inside-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 50%;
                    background-color: $secondary;
                    padding: 4rem;
                }

                img {
                    width: 100%;
                    height: 50%;
                }
            }
        }
    }

    .full-image {
        aspect-ratio: 100 / 47;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .subtitle {
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 400;
    }

    @include media-breakpoint-down(lg) {

        .text-section {
            padding: 2.5rem 0;
        }

        .image-section {
            display: block;
            margin-left: calc((100vw - 100% ) / -2);
            height: auto;

            .left-image {
                width: 100%;
                height: 80vw;
            }

            .right-image {
                width: 100%;

                &.text-inside {
                    .hero-inside-text {
                        padding: 2.5rem 1.5rem;
                    }

                    img {
                        height: 47vw;
                    }
                }

                &:not(.text-inside) {
                    height: 47vw;
                }
            }

            .subtitle {
                font-size: 1rem;
                line-height: 1.5;
            }
        }
    }
}
