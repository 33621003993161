.accordion {
    --#{$prefix}border-radius: 0;
    --#{$prefix}border-color: #{$primary};
    --#{$prefix}accordion-btn-padding-x: 0;
    --#{$prefix}accordion-btn-padding-y: 1rem;
    --#{$prefix}accordion-body-padding-x: 0;
    --#{$prefix}accordion-btn-icon-width: 2rem;
    --#{$prefix}accordion-btn-icon-width: 2rem;
    --#{$prefix}accordion-active-bg: transparent;
    --#{$prefix}accordion-active-color: $black;
    --#{$prefix}accordion-btn-focus-box-shadow: 0;

    &-item {
        border-left: 0;
        border-right: 0;

        &:first-of-type {
            border-top: 0;
        }
    }

    &-button {
        font-size: 1.250rem;
        font-weight: 500;

        &::after {
            content: none
        }
        //custom icon
        &-icon {
            width: 2rem;
            height: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: $grey;
            color: $black;
            border-radius: 50%;
            margin-left: auto;
            text-align: center;

            &::after {
                content: "\F124";
                font-family: "bootstrap-icons";
                line-height: 1;
                font-size: 1.5rem;
                transition: transform .2s ease-in-out;
            }
        }

        &:not(.collapsed) .accordion-button-icon {
            background-color: $primary;

            &::after {
                color: $white;
                transform: rotate(-180deg);
            }
        }
    }

    &-body {
        p:last-of-type {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-down(md) {
        &-button{
            font-size: 1.125rem;
        }
    }
    }
