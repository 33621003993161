.blog-filter {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 30px; /*justify-content: space-around;*/
}

.blog-filter > div {
    margin-top: 10px;
}

.blog-filter a {
    text-decoration: none;
    color: #000;
    font-family: "Oswald", sans-serif;
    font-size: 1.3rem;
    font-weight: 600;
    margin-right: 30px;
    margin-bottom: 15px;
    display: block;
    text-transform: uppercase;
}

.blog-filter .active a {
    color: #554cb7;
}

.blog-detail {
    padding: 250px 0;
}

.blog-detail-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-detail-title-author {
    font-size: 16px;
    text-transform: uppercase;
}

.blog-detail-title-text {
    font-size: 48px;
}

.blog-detail-title-text:after {
    left: 50%;
    margin-left: -40px;
    background-color: #554cb7;
}

.blog-detail-introduction {
    font-size: 14px;
    margin-bottom: 125px !important;
}

.blog-detail-body {
    width: 100%;
    margin-bottom: 30px;
}

.blog-detail-body-title {
    margin-bottom: 75px;
    font-size: 30px;
    text-align: center;
}

.blog-detail-body-title:after {
    left: 50%;
    margin-left: -40px;
    background-color: #554cb7;
}

.blog-detail-body-text {
    font-size: 16px;
}

.blog-detail-body-image {
    margin: 75px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.blog-detail-body-image img {
    width: 100%;
}

.blog-detail-images {
    width: 1920px;
    margin: 135px auto 145px;
}

@media only screen and (max-width: 576px) {
    .blog-detail-images {
        width: 100%;
    }
}

.blog-detail-images-box:nth-child(1) {
    width: 65%;
    margin-right: 35px;
}

.blog-detail-images-box {
    width: 35%;
    object-fit: cover;
}

.blog-detail-images-box div {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.blog-detail-images-box-image {
    width: 100%;
    height: 100%;
}

.blog-detail-quote {
    display: flex;
    align-items: center;
    margin: 30px 0;
}

.blog-detail-quote-bar {
    height: 50px;
    width: 5px;
    background-color: #0b2d44;
}

.blog-detail-quote-text {
    padding-left: 1rem;
}

.blog-detail-quote-text > div > div,
.blog-detail-quote-text > div > div > p {
    margin-bottom: 0;
}


.blog-post img {
    max-width: 100%;
    margin: 75px 0;
    display: block;
}

.blog-post h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 75px;
}

.blog-post h2::after {
    left: 50%;
    margin-left: -40px;
    background-color: #554cb7;
}

.navbar-blog .navbar-brand {
    margin: 0;
}

.navbar-blog .navbar-brand > svg {
    width: fit-content;
    height: 50px;
}
