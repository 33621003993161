html {
    visibility: visible !important;
    max-width: 100%;
    overflow-x: hidden;
    font-size: 16px;
    scrollbar-width: thin;
}

.grecaptcha-badge{
    z-index: 1; // fix for sections in footer
}

    
