.text-bg-primary, .bg-primary {
    --carousel-dot: #{$white};
    --carousel-dot-active: #{$black};
    --card-border-color: transparent;
    --card-border-width: 0;
    --card-bg: #{$black};
    --card-cap-bg: #{$black};
    --card-cap-bg-hover: #{$indigo-900};
}

.text-bg-secondary, .bg-secondary {
    --carousel-dot: #{$black};
    --carousel-dot-active: #{$white};
    --card-border-color: transparent;
    --card-border-width: 0;
    --card-bg: #{$white};
    --card-cap-bg: #{$quaternary};
    --card-cap-bg-hover: #{$orange-800};
}

.text-bg-tertiary, .bg-tertiary {
    --carousel-dot: #{$white};
    --carousel-dot-active: #{$quaternary};
    --card-border-color: transparent;
    --card-border-width: 0;
    --card-bg: #{$white};
    --card-cap-bg: #{$quaternary};
    --card-cap-bg-hover: #{$orange-800};
}

.text-bg-quaternary, .bg-quaternary {
    --carousel-dot: #{$white};
    --carousel-dot-active: #{$black};
    --card-border-color: transparent;
    --card-border-width: 0;
    --card-bg: #{$white};
    --card-cap-bg: #{$black};
    --card-cap-bg-hover: #{$indigo-800};
}

.text-bg-quinary, .bg-quinary {
    --carousel-dot: #{$white};
    --carousel-dot-active: #{$black};
    --card-border-color: transparent;
    --card-border-width: 0;
    --card-bg: #{$white};
    --card-cap-bg: #{$black};
    --card-cap-bg-hover: #{$indigo-800};
}


.text-bg-light, .bg-light {
    --card-border-color: transparent;
    --card-border-width: 0;
    --card-bg: #{$white};
}

.text-bg-dark, .bg-dark {
    --carousel-dot: #{$white};
    --card-border-color: transparent;
    --card-border-width: 0;
    --card-bg: #{$white};
}

.text-bg-grey, .bg-grey {
    --carousel-dot: #{$white};
    --card-border-color: transparent;
    --card-border-width: 0;
    --card-bg: #{$white};
}



@each $color, $value in $theme-colors {
    .bg-#{$color} {
        $card-background-color: $value;
        color: color-contrast($card-background-color,$body-color);
        --#{$prefix}heading-color: color-contrast($card-background-color,$body-color);

        .content-block-content {
            a:not(.btn) {
                color: color-contrast($card-background-color,$black);
            }
        }
    }

    .text-bg-#{$color} {

        $card-background-color: $value;
        color: color-contrast($card-background-color,$body-color);
        --#{$prefix}heading-color: color-contrast($card-background-color,$body-color);

        &.content-block-content a {
            color: color-contrast($card-background-color,$black);
        }
    }
}
