.expertise-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    min-height: 100vh;
    position: relative;
}

.expertise-item-img-wrapper {
    position: relative;
    width: 45%;
}

.expertise-item-content-wrapper {
    position: relative;
    display: flex;
    width: 55%;
}

.expertise-item-content {
    padding: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
}

.expertise-item.is-gray {
    background-color: #f1f1f1;
}

.expertise-item.grow-bottom .expertise-item-img-wrapper {
    margin-bottom: -30px;
}

.expertise-item.move-top .expertise-item-img-wrapper {
    margin-top: -30px;
    margin-bottom: 30px;
}

.expertise-item.move-bottom .expertise-item-img-wrapper {
    margin-top: 30px;
    margin-bottom: -30px;
}
