.cta-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        .cta-block-arrow {
            transform: translateX(10px);
        }
    }

    &-title {
        //color: $white;
        font-size: calc(1.35rem + .3vw);

        &::after {
            content: none;
        }

        @media(min-width: 1200px) {
            font-size: 2rem;
        }
    }

    &-subtitle {
        //color: $white;
        font-size: calc(1.275rem + .3vw);
        text-transform: uppercase;
        opacity: .9;
        margin-bottom: 0;

        @media(min-width: 1200px) {
            font-size: 1.35rem;
        }
    }

    &-arrow {
        align-self: flex-end;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4NCjxzdmcgZmlsbD0iI2ZmZmZmZiIgaGVpZ2h0PSI4MDBweCIgd2lkdGg9IjgwMHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIA0KCSB2aWV3Qm94PSIwIDAgMzEuMTQzIDMxLjE0MyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8ZyBpZD0iYzEwMF9hcnJvdyI+DQoJCTxwYXRoIGQ9Ik0wLDE1LjU3MWMwLjAwMSwxLjcwMiwxLjM4MywzLjA4MSwzLjA4NSwzLjA4M2wxNy41MjgtMC4wMDJsLTQuNzM4LDQuNzM5Yy0xLjI4MywxLjI4NC0xLjM0OSwzLjMwMS0wLjE0NSw0LjUwNw0KCQkJYzEuMjA1LDEuMjAxLDMuMjIyLDEuMTM4LDQuNTA3LTAuMTQ2bDkuODk2LTkuODk4YzEuMjg3LTEuMjgzLDEuMzUyLTMuMzAxLDAuMTQ2LTQuNTA2Yy0wLjAzMy0wLjAyOS0wLjA2OC0wLjA1MS0wLjEtMC4wOA0KCQkJYy0wLjA0MS0wLjA0My0wLjA3LTAuMDk0LTAuMTEzLTAuMTM5bC05Ljc2NC05Ljc2MmMtMS4yNjgtMS4yNjYtMy4yNy0xLjMxNi00LjQ3NC0wLjExMWMtMS4yMDUsMS4yMDUtMS4xNTMsMy4yMDgsMC4xMTEsNC40NzYNCgkJCWw0Ljc1NSw0Ljc1NEgzLjA4NUMxLjM4MSwxMi40ODUsMCwxMy44NjUsMCwxNS41NzF6Ii8+DQoJPC9nPg0KCTxnIGlkPSJDYXBhXzFfNDZfIj4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4=");
        background-position: center;
        background-size: contain;
        display: block;
        height: 25px;
        margin-top: auto;
        width: 25px;
        transition: transform 0.15s ease-in-out;
    }


    &.text-bg-primary {
        &:hover,
        &:active,
        &:focus {
            background-color: $primary-bg-subtle;
            border-color: $primary-border-subtle;
        }
    }

    &.text-bg-secondary {
        &:hover,
        &:active,
        &:focus {
            background-color: $secondary-bg-subtle;
            border-color: $secondary-border-subtle;
        }
    }

    &.text-bg-tertiary {
        background-color: $tertiary;

        &:hover,
        &:active,
        &:focus {
            background-color: $tertiary-bg-subtle;
            border-color: $tertiary-border-subtle;
        }
    }

    &.grey {
        background-color: $gray-500;

        .cta-block-title,
        .cta-block-subtitle {
            color: $black;
        }

        .cta-block-arrow {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4NCjxzdmcgZmlsbD0iIzAwMDAwMCIgaGVpZ2h0PSI4MDBweCIgd2lkdGg9IjgwMHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIA0KCSB2aWV3Qm94PSIwIDAgMzEuMTQzIDMxLjE0MyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8ZyBpZD0iYzEwMF9hcnJvdyI+DQoJCTxwYXRoIGQ9Ik0wLDE1LjU3MWMwLjAwMSwxLjcwMiwxLjM4MywzLjA4MSwzLjA4NSwzLjA4M2wxNy41MjgtMC4wMDJsLTQuNzM4LDQuNzM5Yy0xLjI4MywxLjI4NC0xLjM0OSwzLjMwMS0wLjE0NSw0LjUwNw0KCQkJYzEuMjA1LDEuMjAxLDMuMjIyLDEuMTM4LDQuNTA3LTAuMTQ2bDkuODk2LTkuODk4YzEuMjg3LTEuMjgzLDEuMzUyLTMuMzAxLDAuMTQ2LTQuNTA2Yy0wLjAzMy0wLjAyOS0wLjA2OC0wLjA1MS0wLjEtMC4wOA0KCQkJYy0wLjA0MS0wLjA0My0wLjA3LTAuMDk0LTAuMTEzLTAuMTM5bC05Ljc2NC05Ljc2MmMtMS4yNjgtMS4yNjYtMy4yNy0xLjMxNi00LjQ3NC0wLjExMWMtMS4yMDUsMS4yMDUtMS4xNTMsMy4yMDgsMC4xMTEsNC40NzYNCgkJCWw0Ljc1NSw0Ljc1NEgzLjA4NUMxLjM4MSwxMi40ODUsMCwxMy44NjUsMCwxNS41NzF6Ii8+DQoJPC9nPg0KCTxnIGlkPSJDYXBhXzFfNDZfIj4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4=");
        }
    }
}
