.block-img {
    height: 100%;
    width: 100%;

    /* Default CSS variable values in case they aren't provided */
    --calculated-percentage: 100%;

    &.overlap-left {
        width: calc(100% + ((100vw - var(--calculated-percentage)) / 2));
        margin-left: calc((100vw - var(--calculated-percentage)) / -2);
    }

    &.overlap-right {
        width: calc(100% + ((100vw - var(--calculated-percentage)) / 2));
    }

    @include media-breakpoint-down(lg) {
        &.overlap-left, &.overlap-right {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
