.navbar {
    --#{$prefix}navbar-toggler-padding-y: 0;
    --#{$prefix}navbar-toggler-padding-x: 0;
    --#{$prefix}navbar-toggler-focus-width: 0;

    .navbar-toggler {
        --#{$prefix}border-width: 0;

        .navbar-toggler-icon {
            width: 1.125rem;
            height: 1.125rem;
        }

        &[aria-expanded="true"] {
            .navbar-toggler-icon {
                --#{$prefix}navbar-toggler-icon-bg: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00001 7.55375L1.92701 12.627C1.78851 12.7653 1.61443 12.8362 1.40476 12.8395C1.19526 12.8427 1.01801 12.7718 0.873012 12.627C0.728178 12.482 0.655762 12.3063 0.655762 12.1C0.655762 11.8937 0.728178 11.718 0.873012 11.573L5.94626 6.5L0.873012 1.427C0.734678 1.2885 0.663845 1.11441 0.660512 0.904747C0.657345 0.695247 0.728178 0.517997 0.873012 0.372997C1.01801 0.228163 1.19368 0.155746 1.40001 0.155746C1.60635 0.155746 1.78201 0.228163 1.92701 0.372997L7.00001 5.44625L12.073 0.372997C12.2115 0.234663 12.3856 0.16383 12.5953 0.160497C12.8048 0.15733 12.982 0.228163 13.127 0.372997C13.2718 0.517997 13.3443 0.693664 13.3443 0.899997C13.3443 1.10633 13.2718 1.282 13.127 1.427L8.05376 6.5L13.127 11.573C13.2653 11.7115 13.3362 11.8856 13.3395 12.0952C13.3427 12.3047 13.2718 12.482 13.127 12.627C12.982 12.7718 12.8063 12.8442 12.6 12.8442C12.3937 12.8442 12.218 12.7718 12.073 12.627L7.00001 7.55375Z' fill='%23464B3C' style='fill:%23464B3C;fill:color(display-p3 0.2745 0.2941 0.2353);fill-opacity:1;'/%3E%3C/svg%3E%0A");
            }
        }
    }

    .nav-link {
        --#{$prefix}nav-link-color: #{$black};
        --#{$prefix}nav-link-font-size: 1.15rem;

        &.show {
            --#{$prefix}navbar-active-color: #{$black};
            border-bottom: 1px solid $primary;

            &:after {
                color: $primary;
            }
        }

        &.active {
            --#{$prefix}navbar-active-color: #{$black};
            border-bottom: 1px solid $primary;
        }

        &:hover {
            color: inherit;
            border-bottom: 1px solid $black;
        }
    }

    .dropdown-toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: flex-start;

        &:after {
            content: "\F282";
            font-family: "bootstrap-icons";
            border: 0;
            line-height: 1;
            font-size: 1rem;
            font-weight: 600;
            transition: transform ease-out .3s;
            margin-left: .75rem;
        }

        &[aria-expanded="true"] {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    .dropdown-menu {
        --#{$prefix}dropdown-bg: #{$white};
        --#{$prefix}dropdown-spacer: 0;
        --#{$prefix}dropdown-border-width: 0;
        --#{$prefix}dropdown-padding-y: 0;
        --#{$prefix}dropdown-font-size: 1.15rem;
        --#{$prefix}dropdown-min-width: 300px;
        --#{$prefix}dropdown-link-hover-bg: #{$lightGrey};
        margin-top: 1rem;

        .dropdown-item {
            --#{$prefix}dropdown-item-padding-y: .75rem;
            --#{$prefix}dropdown-link-active-bg: #{$lightGrey};
            --#{$prefix}dropdown-link-active-color: #{$black};
            border-bottom: 1px solid #E3E3E3;
        }
        // dropdown toggle inside dropdown menu (submenu)
        .dropdown-toggle {
            justify-content: space-between;

            &:after {
                transform: rotate(-90deg);
            }
        }
    }
    /* ============ desktop view only ============ */
    @include media-breakpoint-up(md) {

        .dropdown-menu li {
            position: relative;
        }

        .nav-item .submenu {
            display: none;
            position: absolute;
            left: calc(100% + 5px);
            top: 0px;
        }

        .nav-item .submenu-left {
            right: 100%;
            left: auto;
        }
    }
    /* ============ desktop view .end// ============ */
    /* ============ small devices ============ */
    @include media-breakpoint-down(md) {
        .navbar-nav {
            gap: 0;
        }

        .container {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .navbar-collapse {
            padding: 4rem 0 3rem;

            &.show {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                height: calc(100vh - 76px);
                overflow-y: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
        }

        .dropdown-menu {
            --#{$prefix}dropdown-bg: $light;
            margin-top: 0;

            .dropdown-item {
                padding: 1.75rem 0 .75rem;
                font-size: 1.125rem;
                font-weight: 500;
                line-height: 1.05;

                &.show {
                    border-color: $primary;

                    &:after {
                        transform: rotate(-180deg);
                    }
                }
            }

            .dropdown-toggle {
                &:after {
                    transform: none;
                }
            }
        }

        .dropdown-toggle {
            justify-content: space-between;

            &:after {
                content: "\F285";
            }

            &.show {
                justify-content: flex-start;

                &:after {
                    order: -1;
                    margin-left: 0;
                    margin-right: 3rem;
                }
            }
        }

        .nav-link {
            border-bottom: 1px solid $borderColor;
            padding-top: 1.75rem;
            padding-bottom: .75rem;
            font-size: 1.125rem;
            line-height: 1.05;

            &.active:not(.show) {
                border-color: $borderColor
            }

            &.show {
                &:after {
                    color: $black;
                }
            }
        }

        .button-wrapper {
            padding-top: 2rem;
            margin-top: auto;
            display: flex;
            justify-content: center;
        }
        // handling visibility of correct level via styling
        .dropdown {
            &.level-open ~ * {
                display: none;
            }

            &:has(.level-open) > .nav-link {
                display: none;
            }
        }
    }
    /* ============ small devices .end// ============ */
}
