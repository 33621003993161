.modal {
    --#{$prefix}modal-bg: transparent;
    --#{$prefix}modal-header-padding: 0;
    --#{$prefix}modal-header-border-width: 0;
    --#{$prefix}modal-padding: 0;
    --#{$prefix}modal-border-radius: 0;
    --#{$prefix}modal-border-width: 0;
    --#{$prefix}modal-footer-border-width: 0;



    .btn-close {
        --#{$prefix}btn-close-bg: none;
        --#{$prefix}btn-close-color: #{$white};
        --#{$prefix}btn-close-opacity: .8;
        font-size: 1.5rem;
        line-height: 1;
        padding: 0;
        margin: 0 0 0 auto;
        width: auto;
        height: auto;

        &:after {
            font-family: "bootstrap-icons";
            content: "\F622";
        }
    }

    .modal-media {
        width: 100%;
        height: 85vh;
        overflow: hidden;
        /*background-color: $white;
        padding: 1.5rem;
        border-radius: 1rem;
            */
        &.has-video{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    img, video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    video {
        height: auto;
        max-height: 100%;
    }

    &-backdrop {
        --#{$prefix}backdrop-opacity: .6;
    }
}

// grid spacing fix
.umb-block-grid__layout-item:has([data-component="promo-banner"]:not(.show)){
    display: none;
    

}

