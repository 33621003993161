.service-item-background {
    background-color: $gray-100;
}

.service-item-position {
    flex-direction: row-reverse !important;
}

.service-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    min-height: 100vh;
    position: relative;
}

.service-item-img-wrapper {
    position: relative;
    width: 100%;
}

.service-item-content-wrapper {
    position: relative;
    width: 100%;
}

.service-item-content {
    padding: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
}

.service-item-content h3 {
    font-size: 30px;
    margin-bottom: 50px;
}

.service-item.is-gray {
    background-color: $gray-100;
}

.service-item.grow-bottom .service-item-img-wrapper {
    margin-bottom: -30px;
}

.service-item.move-top .service-item-img-wrapper {
    margin-top: -30px;
    margin-bottom: 30px;
}

.service-item.move-top:first-of-type {
    overflow: hidden;
}

.service-item.move-bottom .service-item-img-wrapper {
    margin-top: 30px;
    margin-bottom: -30px;
}

.service-item .is-reversed .service-item-content-wrapper {
    order: 1;
}

.service-item .is-reversed .service-item-img-wrapper {
    order: 2;
}
