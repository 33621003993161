.form-block {
    form {
        display: block;
        margin-top: 0em;
    }
    // structure
    .contactFormRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 2rem;
    }

    .contactFormCol {
        flex: 1 1 300px;
        padding: 0 30px;
    }
    // controls
    .contactFormControl {
        width: 100%;
        border: none;
        padding: 15px 0;
        margin: 0;
        display: inline-flex;
        position: relative;
        min-width: 0;
        flex-direction: column;
        vertical-align: top;

        label {
            display: inline-block;
            transform-origin: top left;
            color: transparentize($black, .3);
            padding: 0;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.00938em;
            margin-bottom: 0.5rem;

            &:focus {
                outline: none;
            }

            &.MuiInputLabel-animated {
                transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            }

            &.MuiInputLabel-formControl {
                top: 0;
                left: 5px;
                position: absolute;
                transform: translate(0, 24px) scale(1);

                &.bottom-spacing {
                    transform: translate(0, 12px) scale(1);

                    &.shrink {
                        transform: translate(0, calc(1.25rem - 12px)) scale(0.75);
                    }
                }
            }

            &.shrink {
                transform: translate(0, 1.25rem) scale(0.75);
                transform-origin: top left;
                color: $black;
            }

            + .MuiInput-formControl {
                margin-top: 16px;
            }
        }

        div.MuiInput-formControl {
            margin-top: 24px !important;
            border: none;
            color: transparentize($black, .15);
            cursor: text;
            display: inline-flex;
            position: relative;
            font-size: 1rem;
            box-sizing: border-box;
            align-items: center;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.1876em;
            letter-spacing: 0.00938em;

            &:focus {
                outline: none;
            }

            &.MuiInput-underline {
                &:before {
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "\00a0";
                    position: absolute;
                    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    border-bottom: 1px solid $black;
                    pointer-events: none;
                }

                &:after {
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "";
                    position: absolute;
                    transform: scaleX(0);
                    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
                    border-bottom: 2px solid $black;
                    pointer-events: none;
                }
            }

            button,
            input,
            optgroup,
            select,
            textarea {
                margin: 0;
                font-family: inherit;
                font-size: inherit;
                line-height: inherit;
            }

            .MuiInputBase-input {
                font: inherit;
                color: $black;
                width: 100%;
                border: 0;
                margin: 0;
                display: block;
                padding: 6px 0 7px;
                min-width: 0;
                background: none;
                box-sizing: content-box;
                animation-name: mui-auto-fill-cancel;
                letter-spacing: inherit;
                animation-duration: 10ms;
                -webkit-tap-highlight-color: transparent;

                &:invalid {
                    box-shadow: none;
                }

                &:focus {
                    outline: none;

                    &:before {
                        border-color: $black;
                    }
                }
            }
            // textarea overwrite
            textarea.MuiInputBase-input {
                border-left: 1px solid $black;
                border-right: 1px solid $black;
                border-top: 1px solid $black;
            }
        }
        //checkbox
        &.contactFormCheckbox {
            input[type="checkbox"] {
                position: absolute;
                height: 1px;
                width: 1px;
                overflow: hidden;
                clip: rect(1px, 1px, 1px, 1px);

                + label {
                    position: relative;
                    align-items: center;
                    cursor: pointer;
                    color: $black;
                    transform: none;

                    &:before {
                        content: "";
                        position: relative;
                        display: inline-block;
                        margin-right: 0.625rem;
                        width: 20px;
                        height: 20px;
                        top: 0.1875rem;
                        border: 1px solid $black;
                        background: $white;
                    }
                }

                &:checked {
                    + label:after {
                        content: "";
                        position: absolute;
                        top: 0.5rem;
                        left: 0.1875rem;
                        border-left: 3px solid $primary;
                        border-bottom: 3px solid $primary;
                        height: 7px;
                        width: 14px;
                        transform: rotate(-45deg);
                    }
                }
            }

            &.error {
                input[type="checkbox"] + label:before {
                    border-color: $red;
                }
            }
        }

        &.error {
            .MuiInput-underline::before {
                border-color: $red;
            }

            label {
                color: $red
            }
        }
    }
    // error blocks
    .formSubmitSuccess {
        width: 100%;
        background-color: $success;
    }

    .formSubmitError {
        width: 100%;
        background-color: $danger;
    }

    .formSubmitError, .formSubmitSuccess {
        margin-bottom: 30px;
        padding: 5px 15px;

        &, p {
            color: $white;
            font-size: 1rem;
            font-style: italic;
            margin-bottom: 0
        }
    }
}
