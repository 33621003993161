section {
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    //min-height: 50vh;
    position: relative;
    padding: 80px 0;



    footer & {
        padding: 25px 0;
    }
    // full width bg-block
    &.full-width-block {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100vw;
            height: 100%;
            left: calc((100vw - 100%)/-2);
            top: 0;
            z-index: -1;
        }
    }

    &.no-column-gap {
        .umb-block-grid__layout-container {
            column-gap: 0;
        }
    }

    &.no-row-gap {
        .umb-block-grid__layout-container {
            row-gap: 0;
        }
    }


    @include media-breakpoint-down(md) {
        padding: 40px 0;
        // reduce section spacing for mobile

        $spacer: 0.5rem;

        $spacers: ( 0: 0, 1: $spacer * .25, 2: $spacer * .5, 3: $spacer, 4: $spacer * 1.5, 5: $spacer * 3, 6: $spacer * 3.5, 7: $spacer * 4, 8: $spacer * 4.5, 9: $spacer * 5, 10: $spacer * 5.5 );


        @each $key, $value in $spacers {
            &.py-#{$key} {
                padding-top: $value !important;
                padding-bottom: $value !important;
            }

            &.pb-#{$key} {
                padding-bottom: $value !important;
            }
        }
    }

    .half-width-full-width {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 50vw;
            height: 100%;
            left: calc((100vw - 200%)/-2);
            top: 0;
            z-index: -1;
            background-color: inherit;
        }

        &.right {
            &::after {
                left: auto;
                right: calc((100vw - 200%)/-2);
            }
        }

        @include media-breakpoint-down(md) {
            &, &.right {
                &::after {
                    width: 100vw;
                    right: auto;
                    left: calc((100vw - 100%)/-2);
                }
            }
        }
    }
}





@each $name, $color in $theme-colors {
    .full-width-block.bg-#{$name}::after {
        background-color: $color;
    }
}
