.owl-carousel {
    .owl-item {
        img {
            aspect-ratio: 16 / 9;
            object-fit: cover;
        }
    }

    &.owl-loaded {
        .owl-stage {
            display: flex;
        }
    }

    .owl-dots .owl-dot {
        span {
            background: var(--carousel-dot, #{$gray-500});
        }

        &.active span {
            background: var(--carousel-dot-active, #{$tertiary});
        }

        &:hover span {
            background: var(--carousel-dot-active, #{$tertiary});
        }
    }
}
