body {
    max-width: 100%;
    overflow-x: hidden;
    font-size: 16px; // 1.15rem
    margin: 0;
    padding: 0;
    font-family: "Manrope", sans-serif;
    font-weight: 400;

    // fixed header catch
    padding-top: 76px;

    &.modal-open{
       padding-right: 0 !important;
       .fixed-top {
           padding-right: 0 !important;
       }
    }
}
