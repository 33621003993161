.about-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;

    &::-webkit-scrollbar {
        height: 7px;
        width: 0;
        background-color: #2b78999f;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #0b2d44;
        border: 1px solid #000;
    }

    > img {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 30px;

        > img {
            width: 75%;
            margin-bottom: 0;
        }
    }
}

.about-images-image {
    margin-bottom: 30px;
}

.about-images-image-space {
    margin-top: 80px;

    @include media-breakpoint-down(md) {
        margin-top: 0;
    }
}
